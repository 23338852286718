.numpad-button {
    width: 100%;
    height: 100px; /* Buton yüksekliğini ayarlayın, istediğiniz boyuta göre düzenleyin */
    font-size: 1.5rem; /* Yazı boyutunu ayarlayın */
    border: 0.1px solid #d0d0d0; /* Buton kenarlıklarını kaldırın */
    background-color: #f0f0f0; /* Arka plan rengini ayarlayın */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .numpad-button.active {
    background-color: #ff9900; /* Mavi arka plan */
    color: white; /* Beyaz yazı rengi */
    border: 1px solid #ff9900; /* Daha koyu bir kenarlık */
}

  
  .col {
    padding: 0; /* Sütunlar arasındaki boşluğu kaldırın */
  }
  
  .row {
    margin: 0; /* Satırlar arasındaki boşluğu kaldırın */
  }
  